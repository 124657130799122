import React, {useCallback, useState} from 'react';
import {Header} from "../../shared/partials/Header";
import {AppHolder} from "../../shared/layout/AppHolder";
import {AppContent} from "../../shared/layout/AppContent";
import {PageContent} from "../../shared/layout/PageContent";
import {SearchBar} from "./SearchBar";
import {searchForGamesWithKeyword} from "../../../api/GameService";
import {useDispatch, useSelector} from "react-redux";
import {setFilteredGames} from "../../../redux/actions/GameActions";
import {Results} from "../../shared/other/results/Results";
import {RootState} from "../../../redux/reducers/rootReducer";
import {setSearchKeyword} from "../../../redux/actions/FilterActions";
import {Spacer} from "../../shared/layout/Spacer";
import {OnScreenKeyboard} from "../../shared/other/OnScreenKeyboard";
import {debounce} from "lodash";
import {Helmet} from "react-helmet";

type Props = {};

export function Search(props: Props) {
    const {filteredGames} = useSelector((state: RootState) => state.gameReducer);
    const {searchKeyword} = useSelector((state: RootState) => state.filterReducer);

    const dispatch = useDispatch();

    const [message, setMessage] = useState<string>();

    const filter = useCallback(
        debounce(value => {
            filterGames(value)
        }, 500),
        []
    );

    const updateSearchKeyword = (value: string) => {
        dispatch(setSearchKeyword(value));

        filter(value);
    };

    const filterGames = (keyword: string) => {
        if (keyword) {
            if (keyword.length >= 3 && keyword.length <= 50) {
                if (message) setMessage('');

                getGamesWithKeyword(keyword);
            } else {
                dispatch(setFilteredGames([]));
                setMessage('De zoekterm moet tussen 3 en 50 karakters bevatten');
            }
        } else {
            dispatch(setFilteredGames([]));
            setMessage('Geef een zoekterm in');
        }
    };

    const getGamesWithKeyword = (keyword: string) => {
        searchForGamesWithKeyword(keyword)
            .then(games => {
                dispatch(setFilteredGames(games));

                if (!games.length) {
                    setMessage('Geen spellen gevonden met deze zoekterm');
                }
            })
    }

    return (
        <>
            <Helmet>
                <title>Zoeken</title>
            </Helmet>

            <Header showHomeButton showBackButton/>

            <AppHolder>
                <AppContent>
                    <PageContent>
                        <SearchBar
                            keyword={searchKeyword}
                        />

                        <Results games={filteredGames}/>

                        {
                            message ? <>
                                <Spacer count={2}/>
                                <p>{message}</p>
                            </> : null
                        }
                    </PageContent>
                </AppContent>
            </AppHolder>

            <div className="keyboard__holder">
                <OnScreenKeyboard
                    show
                    onChange={updateSearchKeyword}
                />
            </div>
        </>
    );
}
