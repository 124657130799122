// @flow
import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {getGameById} from "../../../../api/GameService";
import {Header} from "../../../shared/partials/Header";
import {AppHolder} from "../../../shared/layout/AppHolder";
import {AppContent} from "../../../shared/layout/AppContent";
import {setDecimalSeparatorToComma} from "../../../../helpers/StringHelper";
import {GamePropertiesTable} from "./GamePropertiesTable";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentGame} from "../../../../redux/actions/GameActions";
import {RootState} from "../../../../redux/reducers/rootReducer";
import {Helmet} from "react-helmet";

type Props = {};

export function GameDetail(props: Props) {
    const routeParameters = useParams();
    const dispatch = useDispatch();

    const {currentGame} = useSelector((state: RootState) => state.gameReducer);

    useEffect(() => {
        let unmounted = false;
        const id = routeParameters.gameId ? parseInt(routeParameters.gameId) : null;

        if (id && !currentGame) {
            getGameById(id)
                .then(game => {
                    if (!unmounted) {
                        dispatch(setCurrentGame(game));
                    }
                })
        }

        return () => {
            unmounted = true;
        };
    }, [routeParameters.gameId]);

    useEffect(() => {
        return () => {
            dispatch(setCurrentGame(null));
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>Speldetail</title>
            </Helmet>

            <Header showBackButton showHomeButton/>

            <AppHolder>
                <AppContent>
                    {
                        currentGame ? <div className="game__detail">
                            <div className="game__detail__image">
                                <img src={currentGame.image} alt="game cover"/>
                            </div>
                            <div className="game__detail__content">
                                <h1>{currentGame.name}</h1>
                                <p>{currentGame.description}</p>
                                <span
                                    className="game__detail__price">€ {setDecimalSeparatorToComma(currentGame.price)}
                                </span>

                                <GamePropertiesTable game={currentGame}/>
                            </div>
                        </div> : null
                    }
                </AppContent>
            </AppHolder>
        </>
    );
}
