// @flow
import React from 'react';
import {Header} from "../../shared/partials/Header";
import {AppHolder} from "../../shared/layout/AppHolder";
import {AppContent} from "../../shared/layout/AppContent";
import {ChoiceItem} from "./ChoiceItem";
import {useNavigate} from "react-router-dom";
import {Casino, Search, DocumentScanner} from "@mui/icons-material";
import {Helmet} from "react-helmet";

type Props = {};

export function Start(props: Props) {
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>Start</title>
            </Helmet>

            <Header showHomeButton/>

            <AppHolder>
                <AppContent>
                    <div className="choices">
                        <ChoiceItem
                            icon={<Search/>}
                            title="Ik zoek een specifiek spel"
                            content="Je weet wat je zoekt, en wilt de locatie of voorraad ontdekken."
                            onClick={() => navigate('/search')}
                        />
                        <ChoiceItem
                            icon={<Casino/>}
                            title="Ik wil een spel ontdekken"
                            content="Wij helpen jou een spel te vinden d.m.v. enkele korte vragen"
                            onClick={() => navigate('/discover/1')}
                        />
                        <ChoiceItem
                            icon={<DocumentScanner/>}
                            title="Ik wil een spel scannen"
                            content="Je hebt een spel gevonden en wil meer informatie"
                            onClick={() => navigate('/scan')}
                        />
                    </div>
                </AppContent>
            </AppHolder>
        </>
    );
}
