import produce from "immer";
import {
    RESET_FILTERS,
    SET_AGE,
    SET_DURATION,
    SET_EXPERIENCE,
    SET_PLAYER_COUNT,
    SET_SEARCH_KEYWORD
} from "../types/FilterActionTypes";

interface InitialState {
    age: number
    playerCount: number,
    duration: string,
    experience: number,
    searchKeyword: string,
}

const initialState: InitialState = {
    age: 0,
    playerCount: 0,
    duration: '',
    experience: 0,
    searchKeyword: '',
};

type Action = {
    type: string,
    payload: any,
}

export const filterReducer = (state = initialState, action: Action) => {
    return produce(state, nextState => {
        switch (action.type) {
            case SET_AGE:
                nextState.age = action.payload;
                break;
            case SET_PLAYER_COUNT:
                nextState.playerCount = action.payload;
                break;
            case SET_DURATION:
                nextState.duration = action.payload;
                break;
            case SET_EXPERIENCE:
                nextState.experience = action.payload;
                break;
            case SET_SEARCH_KEYWORD:
                nextState.searchKeyword = action.payload;
                break;
            case RESET_FILTERS:
                nextState.age = 0;
                nextState.playerCount = 0;
                nextState.duration = '';
                nextState.experience = 0;
                nextState.searchKeyword = '';
                break;
        }
    })
}
