// @flow
import * as React from 'react';

type Props = {
    image: string,
    onClick: Function,
};

export function SpotLightItem(props: Props) {
    return (
        <span className="spotlight__item" onClick={() => props.onClick()}>
            <img src={props.image} alt="game cover"/>
        </span>
    );
}
