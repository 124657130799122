// @flow
import * as React from 'react';
import {Search} from '@mui/icons-material';

type Props = {
    onClick: Function,
};

export function StartButton(props: Props) {
    return (
        <span className="button button--icon-before button--red" onClick={() => props.onClick()}>
            <Search/> Start met een spel zoeken
        </span>
    );
}
