import React from 'react';
import {Search as SearchIcon} from "@mui/icons-material";
import {debounce} from 'lodash';

type Props = {
    keyword: string,
    onKeywordChange?: Function,
};

export function SearchBar(props: Props) {
    const updateKeyword = debounce((value: string) => {
        if (props.onKeywordChange) {
            props.onKeywordChange(value);
        }
    }, 500);

    const onInputChange = (value: string) => {
        if (props.onKeywordChange) {
            return updateKeyword(value);
        }
    }

    return (
        <div className="search__input">
            <span><SearchIcon/></span>
            <input
                type="search"
                placeholder="Zoek op titel..."
                value={props.keyword}
                onChange={(event) => onInputChange(event.target.value)}
            />
            {/*<span className="button button--red">Zoeken</span>*/}
        </div>
    );
}
