import * as React from 'react';
import {searchForGameWithEanNumber} from "../../../api/GameService";
import {setCurrentGame} from "../../../redux/actions/GameActions";
import {captureException} from "@sentry/react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import BarcodeReader from "react-barcode-reader";

type Props = {
    setMessage?: Function,
};

export function BarcodeScanner(props: Props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const updateMessage = (message: string) => {
        if (props.setMessage) {
            props.setMessage(message);
        }
    }

    const handleScan = (barcode: string) => {
        if (barcode.length) {
            // Remove first character, which is not a part of the ean number
            const eanNumber = barcode.substring(1, barcode.length);

            searchForGameWithEanNumber(eanNumber)
                .then(game => {
                    if (game) {
                        updateMessage('Spel gevonden!');

                        dispatch(setCurrentGame(game));
                        navigate(`/games/${game.id}`);
                    } else {
                        updateMessage('Spel niet gevonden');
                    }
                })
                .catch(error => {
                    updateMessage('Er is iets onverwachts misgegaan');

                    captureException(error);
                })
        } else {
            updateMessage('Fout bij het lezen van de barcode');
        }
    }

    return (
        <BarcodeReader
            onError={error => captureException(error)}
            onScan={handleScan}
        />
    );
}
