// @flow
import * as React from 'react';
import {useNavigate} from "react-router-dom";
import logo from '../../../assets/images/logo.svg';
import {ArrowBack, Home as HomeIcon} from "@mui/icons-material";

type Props = {
    showBackButton: boolean,
    showHomeButton: boolean,
    onBackClick: Function,
};

Header.defaultProps = {
    showBackButton: false,
    showHomeButton: false,
    onBackClick: null,
}

export function Header(props: Props) {
    const navigate = useNavigate();

    const getOnBackClick = () => {
        if (props.onBackClick) {
            return () => props.onBackClick();
        } else {
            return () => navigate(-1);
        }
    }

    return (
        <header className="app__header">
            {
                props.showBackButton ?
                    <span className="app__header__buttonholder">
                        <BackButton onClick={getOnBackClick()}/>
                    </span> :
                    <span className="app__header__buttonholder">&nbsp;</span>
            }
            <span onClick={() => navigate('/')}>
                <img src={logo} alt="logo"/>
            </span>
            {props.showHomeButton ?
                <span className="app__header__buttonholder">
                    <span className="button button--outline button--icon" onClick={() => navigate('/')}>
                        <HomeIcon/>
                    </span>
                </span> :
                <span className="app__header__buttonholder">&nbsp;</span>
            }
        </header>
    );
};

type BackButtonProps = {
    onClick: Function,
}

function BackButton(props: BackButtonProps) {
    return (
        <span className="button button--icon" onClick={() => props.onClick()}>
            <ArrowBack/>
        </span>
    )
}
