import {Game} from "../models/Game";
import {GameFilters} from "../models/types/GameFilters";
import {numberIsInRange, removeNonNumericCharacters} from "./StringHelper";
import {MIN_120, MIN_15} from "../constants/GameDurations";
import {AGE} from "../constants/Questions";

export function filterGames(games: Array<Game>, filters: GameFilters): Array<Game> {
    if (filters.age) {
        games = filterGamesByAge(games, filters.age);
    }

    if (filters.playerCount) {
        games = filterByPlayerCount(games, filters.playerCount);
    }

    if (filters.duration) {
        games = filterByDuration(games, filters.duration);
    }

    return games;
}

/**
 * Checks if the duration defined in the constants is found in the duration string from the API
 *
 * @param durationString Format: 60-90 min -> Comes from (external) API
 * @param duration Format: MIN_60_90 -> this is a constant defined in GameDuration
 */
export function durationFoundInDurationString(durationString: string, duration: string): boolean {
    let matched = false;

    durationString = removeNonNumericCharacters(durationString).trim();

    if (durationString.includes('-')) {
        const explodedDuration = durationString.split('-');
        const startRange = explodedDuration[0];
        const endRange = explodedDuration[1];

        if (duration.includes(startRange) && duration.includes(endRange)) {
            matched = true;
        }
    } else {
        if (duration === MIN_15 && durationString.includes('15')) {
            matched = true;
        }

        if (duration === MIN_120 && durationString.includes('120')) {
            matched = true;
        }
    }

    return matched;
}

function filterGamesByAge(games: Array<Game>, ageToFilter: number): Array<Game> {
    return games.filter((game: Game) => {
        const ageOfGame = game.properties.age ?
            parseInt(game.properties.age.replace('+', '')) :
            8

        let shouldFilter = false;

        switch (ageToFilter) {
            case AGE.ANSWER_1:
                if (ageOfGame <= 8) {
                    shouldFilter = true;
                }
                break;
            case AGE.ANSWER_2:
                if (ageOfGame >= 8 && ageOfGame <= 12) {
                    shouldFilter = true;
                }
                break;
            case AGE.ANSWER_3:
                if (ageOfGame > 12) {
                    shouldFilter = true;
                }
                break;
        }

        return shouldFilter;
    });
}

function filterByPlayerCount(games: Array<Game>, playerCountToFilter: number): Array<Game> {
    return games.filter((game: Game) => {
        return game.properties.amountOfPlayers ? numberIsInRange(playerCountToFilter, game.properties.amountOfPlayers) : false;
    });
}

function filterByDuration(games: Array<Game>, durationToFilter: string): Array<Game> {
    return games.filter((game: Game) => {
        let gameDuration = game.properties.duration;

        if (!gameDuration || !durationToFilter) return false;

        return durationFoundInDurationString(gameDuration, durationToFilter);
    })
}
