// @flow
import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Header} from "../../shared/partials/Header";
import {AppHolder} from "../../shared/layout/AppHolder";
import {AppContent} from "../../shared/layout/AppContent";
import {AgeQuestion} from "./AgeQuestion";
import {QUESTIONS} from "../../../constants/Questions";
import {PlayerCountQuestion} from "./PlayerCountQuestion";
import {ExperienceQuestion} from "./ExperienceQuestion";
import {DiscoverResults} from "./DiscoverResults";
import {DurationQuestion} from "./DurationQuestion";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers/rootReducer";
import {setAge, setDuration, setExperience, setPlayerCount} from "../../../redux/actions/FilterActions";
import {filterGames} from "../../../helpers/FilterHelper";
import {GameFilters} from "../../../models/types/GameFilters";
import {setFilteredGames} from "../../../redux/actions/GameActions";
import {Helmet} from "react-helmet";

type Props = {};

export function Discover(props: Props) {
    const {questionNumber} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {age, playerCount, duration, experience} = useSelector((state: RootState) => state.filterReducer);
    const {gamesToFilter} = useSelector((state: RootState) => state.gameReducer);

    useEffect(() => {
        if (!age) {
            returnToQuestion(QUESTIONS.AGE);
            return;
        }
        if (!playerCount) {
            returnToQuestion(QUESTIONS.PLAYER_COUNT);
            return;
        }
        if (!experience) {
            returnToQuestion(QUESTIONS.EXPERIENCE);
            return;
        }
        if (!duration) {
            returnToQuestion(QUESTIONS.DURATION);
        }
    }, [age, playerCount, duration, experience]);

    useEffect(() => {
        if (questionNumber) {
            const filters = setStateForQuestion(questionNumber);
            applyFilterToGames(filters);
        }
    }, [questionNumber]);

    const setStateForQuestion = (questionToReturnTo: string): GameFilters => {
        const nextFilters = {
            age: age,
            playerCount: playerCount,
            experience: experience,
            duration: duration,
        } as GameFilters

        switch (questionToReturnTo) {
            case QUESTIONS.AGE:
                nextFilters.age = 0;
                nextFilters.playerCount = 0;
                nextFilters.experience = 0;
                nextFilters.duration = '';
                break;
            case QUESTIONS.PLAYER_COUNT:
                nextFilters.playerCount = 0;
                nextFilters.experience = 0;
                nextFilters.duration = '';
                break;
            case QUESTIONS.EXPERIENCE:
                nextFilters.experience = 0;
                nextFilters.duration = '';
                break;
            case QUESTIONS.DURATION:
                nextFilters.duration = '';
                break;
        }

        dispatch(setAge(nextFilters.age));
        dispatch(setPlayerCount(nextFilters.playerCount));
        dispatch(setExperience(nextFilters.experience));
        dispatch(setDuration(nextFilters.duration));

        return nextFilters;
    }

    const returnToQuestion = (questionToReturnTo: string) => {
        navigate(`/discover/${questionToReturnTo}`);
    }

    const applyFilterToGames = (filters: GameFilters) => {
        dispatch(setFilteredGames(filterGames(gamesToFilter, filters)));
    }

    const navigateToNextQuestion = () => {
        const questions = Object.values(QUESTIONS);
        let nextQuestion = questions.indexOf(questionNumber ?? '') + 1;

        if (nextQuestion === 0) {
            // Go to first
            return 1;
        } else {
            nextQuestion++;
        }

        // Check if the next step is the last step
        if (nextQuestion > questions.length) {
            // Go to last step and prevent incrementing further than possible
            nextQuestion = questions.length + 1
        }

        navigate(`/discover/${nextQuestion}`);
    }

    const goBack = () => {
        const previousQuestion = questionNumber ? parseInt(questionNumber) - 1 : 0;

        if (questionNumber === QUESTIONS.AGE || previousQuestion < 1) {
            navigate('/start');
        } else {
            navigate(`/discover/${previousQuestion}`);
        }
    }

    const updateAgeAnswer = (ageAnswer: number) => {
        dispatch(setAge(ageAnswer));
        navigateToNextQuestion();
    }

    const updatePlayerCountAnswer = (playerCountAnswer: number) => {
        dispatch(setPlayerCount(playerCountAnswer));
        navigateToNextQuestion();
    }

    const updateExperienceAnswer = (experienceAnswer: number) => {
        dispatch(setExperience(experienceAnswer));
        navigateToNextQuestion();
    }

    const updateDurationAnswer = (durationAnswer: string) => {
        dispatch(setDuration(durationAnswer));
        navigateToNextQuestion();
    }

    const getStepComponent = () => {
        let component;

        switch (questionNumber) {
            case QUESTIONS.AGE:
                component = <AgeQuestion onClick={(answer: number) => updateAgeAnswer(answer)}/>
                break;
            case QUESTIONS.PLAYER_COUNT:
                component = <PlayerCountQuestion onClick={(answer: number) => updatePlayerCountAnswer(answer)}/>
                break;
            case QUESTIONS.EXPERIENCE:
                component = <ExperienceQuestion onClick={(answer: number) => updateExperienceAnswer(answer)}/>
                break;
            case QUESTIONS.DURATION:
                component = <DurationQuestion onClick={(answer: string) => updateDurationAnswer(answer)}/>
                break;
            case QUESTIONS.RESULT:
                component = <DiscoverResults/>
                break;
        }

        return component;
    }

    return (
        <>
            <Helmet>
                <title>Ontdekken</title>
            </Helmet>

            <Header
                showHomeButton
                showBackButton
                onBackClick={() => goBack()}
            />

            <AppHolder>
                <AppContent>
                    {getStepComponent()}
                </AppContent>
            </AppHolder>
        </>
    );
}
