// @flow
import * as React from 'react';
import {QuestionHolder} from "./QuestionHolder";
import {AnswerButton} from "./AnswerButton";
import {EXPERIENCE} from "../../../constants/Questions";

type Props = {
    onClick: Function,
};

export function ExperienceQuestion(props: Props) {
    return (
        <QuestionHolder content="Hoeveel ervaring hebben de spelers?">
            <AnswerButton content="Beginner (Monopoly, ...)" onClick={() => props.onClick(EXPERIENCE.ANSWER_1)}/>
            <AnswerButton content="Gemiddeld (Catan, ...)" onClick={() => props.onClick(EXPERIENCE.ANSWER_2)}/>
            <AnswerButton content="Expert (Dungeons & Dragons, ...)" onClick={() => props.onClick(EXPERIENCE.ANSWER_3)}/>
        </QuestionHolder>
    );
};
