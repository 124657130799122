import {SET_CURRENT_GAME, SET_FILTERED_GAMES, SET_GAMES_TO_FILTER, SET_POPULAR_GAMES} from "../types/GameActionTypes";
import {Game} from "../../models/Game";

export function setPopularGames(games: Array<Game>) {
    return {
        type: SET_POPULAR_GAMES,
        payload: games,
    }
}

export function setGamesToFilter(games: Array<Game>) {
    return {
        type: SET_GAMES_TO_FILTER,
        payload: games,
    }
}

export function setFilteredGames(games: Array<Game>) {
    return {
        type: SET_FILTERED_GAMES,
        payload: games,
    }
}

export function setCurrentGame(game: Game | null) {
    return {
        type: SET_CURRENT_GAME,
        payload: game,
    }
}
