// @flow
import * as React from 'react';

type Props = {
    content: string,
    onClick: Function,
};

export function AnswerButton(props: Props) {
    return (
        <li className="question__item" onClick={() => props.onClick()}>
            <span>{props.content}</span>
        </li>
    );
}
