import React from 'react';

type Props = {
    icon: JSX.Element,
    title: string,
    content: string,
    onClick: Function,
};

export function ChoiceItem(props: Props) {
    return (
        <div className="choices__item" onClick={() => props.onClick()}>
            <span>{props.icon}</span>
            <h1>{props.title}</h1>
            <p>{props.content}</p>
        </div>
    );
}
