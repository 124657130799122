import {Game} from "../models/Game";
import {getBaseUrl} from "./index";
import axios from "axios";
import {GameData} from "../models/types/GameData";

export async function getPopularGames(): Promise<Array<Game>> {
    return axios.get(getBaseUrl() + 'products/bestsellers')
        .then(response => {
            if (response.data.success) {
                return response.data.data.map((gameData: GameData) => {
                    return new Game(gameData);
                })
            }
        })
}

export async function getGameById(id: number): Promise<Game> {
    return axios.get(getBaseUrl() + 'products/' + id)
        .then(response => {
            if (response.data.success) {
                return new Game(response.data.data);
            } else {
                throw Error(response.data.message);
            }
        })
}

export async function getGamesToFilter(): Promise<Array<Game>> {
    return axios.get(getBaseUrl() + 'products')
        .then(response => {
            if (response.data.success) {
                return response.data.data.map((gameData: GameData) => {
                    return new Game(gameData);
                })
            } else {
                throw Error(response.data.message);
            }
        })
}

export async function searchForGamesWithKeyword(keyword: string): Promise<Array<Game>> {
    const data = {
        params: {
            keyword: keyword
        }
    }

    return axios.get(getBaseUrl() + 'products/search/keyword', data)
        .then(response => {
            if (response.data.success) {
                return response.data.data.map((gameData: GameData) => {
                    return new Game(gameData);
                })
            } else {
                throw Error(response.data.message);
            }
        })
}

export async function searchForGameWithEanNumber(eanNumber: string): Promise<Game|null> {
    const data = {
        params: {
            eanNumber: eanNumber
        }
    }

    return axios.get(getBaseUrl() + 'products/search/scan', data)
        .then(response => {
            if (response.data.success) {
                return response.data.data ? new Game(response.data.data) : null;
            } else {
                throw Error(response.data.message);
            }
        })
}
