import { createStore } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import {rootReducer} from "../reducers/rootReducer";

const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25,
})

export const store = createStore(rootReducer, composeEnhancers());

