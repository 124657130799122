// @flow
import * as React from 'react';
import {AnswerButton} from "./AnswerButton";
import {AGE} from "../../../constants/Questions";
import {QuestionHolder} from "./QuestionHolder";

type Props = {
    onClick: Function,
};

export function AgeQuestion(props: Props) {
    return (
        <QuestionHolder content="Voor welke leeftijd zoek je een spel?">
            <AnswerButton content="Tot 8 jaar" onClick={() => props.onClick(AGE.ANSWER_1)}/>
            <AnswerButton content="8 tot 12 jaar" onClick={() => props.onClick(AGE.ANSWER_2)}/>
            <AnswerButton content="Vanaf 12 jaar" onClick={() => props.onClick(AGE.ANSWER_3)}/>
        </QuestionHolder>
    );
}
