import * as React from 'react';
import {Header} from "../../shared/partials/Header";
import {AppHolder} from "../../shared/layout/AppHolder";
import {AppContent} from "../../shared/layout/AppContent";
import {PageContent} from "../../shared/layout/PageContent";
import {StartButton} from "./StartButton";
import {Spacer} from "../../shared/layout/Spacer";
import {SpotLight} from "./SpotLight";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";

type HomeProps = {}

export const Home = (props: HomeProps) => {
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>Home</title>
            </Helmet>

            <Header/>

            <AppHolder>
                <AppContent>
                    <PageContent>
                        <StartButton onClick={() => navigate('/start')}/>

                        <Spacer count={2}/>
                        <Spacer count={2}/>

                        <p>... of ontdek onze meest <strong>populaire spelletjes</strong>:</p>

                        <Spacer count={2}/>

                        <SpotLight/>
                    </PageContent>
                </AppContent>
            </AppHolder>
        </>
    );
};
