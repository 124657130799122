export function removeHtmlFromString(text: string): string {
    const regex = /(<([^>]+)>)/ig;

    return text.replace(regex, '');
}

export function setDecimalSeparatorToComma(number: number, precision: number = 2): string {
    let result;
    result = number.toFixed(precision);

    return result.replace('.', ',');
}

export function removeNonNumericCharacters(text: string): string {
    return text.replace(/[^\d.-]/g, '');
}

export function numberIsInRange(number: number, range: string): boolean {
    if (!range) return false;

    let shouldFilter = false;

    if (range.includes('-')) {
        let explodedString = range.split('-');
        const startOfRange = parseInt(explodedString[0]);
        const endOfRange = parseInt(explodedString[1]);

        if (number >= startOfRange && number <= endOfRange) {
            shouldFilter = true;
        }

    } else {
        shouldFilter = range.includes(number.toString())
    }

    return shouldFilter;
}
