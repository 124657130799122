import {MIN_120, MIN_15, MIN_15_30, MIN_30_60, MIN_60_90, MIN_90_120} from "./GameDurations";

export const QUESTIONS = {
    AGE: '1',
    PLAYER_COUNT: '2',
    EXPERIENCE: '3',
    DURATION: '4',
    RESULT: '5',
};

export const AGE = {
    ANSWER_1: 1,
    ANSWER_2: 2,
    ANSWER_3: 3,
};

export const PLAYER_COUNT = {
    ANSWER_1: 1,
    ANSWER_2: 2,
    ANSWER_3: 4,
    ANSWER_4: 6,
    ANSWER_5: 8,
    ANSWER_6: 10,
};

const BEGINNER = 'BEGINNER';
const INTERMEDIATE = 'INTERMEDIATE';
const EXPERT = 'EXPERT';

export const EXPERIENCE = {
    ANSWER_1: BEGINNER,
    ANSWER_2: INTERMEDIATE,
    ANSWER_3: EXPERT,
};

export const DURATION = {
    ANSWER_1: MIN_15,
    ANSWER_2: MIN_15_30,
    ANSWER_3: MIN_30_60,
    ANSWER_4: MIN_60_90,
    ANSWER_5: MIN_90_120,
    ANSWER_6: MIN_120,
};
