import React, {useEffect} from 'react';
import {Routes, Route, useLocation} from "react-router-dom";
import {Home} from "./components/routes/home/Home";
import './scss/style.scss';
import {getGamesToFilter, getPopularGames} from "./api/GameService";
import {useDispatch, useSelector} from "react-redux";
import {setFilteredGames, setGamesToFilter, setPopularGames} from "./redux/actions/GameActions";
import {GameDetail} from "./components/routes/games/detail/GameDetail";
import {Start} from "./components/routes/start/Start";
import {Discover} from "./components/routes/discover/Discover";
import {Scan} from "./components/routes/scan/Scan";
import {Maintenance} from "./components/routes/maintenance/Maintenance";
import {Search} from "./components/routes/search/Search";
import {resetFilters} from "./redux/actions/FilterActions";
import ReactGA from "react-ga4";
import {setupInterceptors} from "./api";
import {RootState} from "./redux/reducers/rootReducer";
import {BarcodeScanner} from "./components/shared/other/BarcodeScanner";
import {setScanMessage} from "./redux/actions/AppActions";

function App() {
    const location = useLocation();
    const dispatch = useDispatch();

    const {popularGames, gamesToFilter} = useSelector((state: RootState) => state.gameReducer);

    const home = '/';
    const start = '/start';

    useEffect(() => {
        ReactGA.initialize('G-6EQJPF5JWT');
        setupInterceptors();
    }, [])

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: location.pathname});
    }, [location.pathname])

    useEffect(() => {
        let unmounted = false;

        if (!popularGames || !popularGames.length || !gamesToFilter || !gamesToFilter.length) {
            getPopularGames()
                .then(gameList => {
                    if (!unmounted) {
                        dispatch(setPopularGames(gameList));
                    }
                });

            getGamesToFilter()
                .then(gameList => {
                    if (!unmounted) {
                        dispatch(setGamesToFilter(gameList));
                    }
                });
        }
    }, [popularGames, gamesToFilter, location.pathname]);

    useEffect(() => {
        if ([home, start].includes(location.pathname)) {
            dispatch(setFilteredGames([]));
            dispatch(resetFilters());
        }
    }, [location.pathname]);

    return (
        <div className="app">
            <BarcodeScanner setMessage={(message: string) => dispatch(setScanMessage(message))}/>

            <Routes>
                <Route path={home} element={<Home/>}/>
                <Route path="games/:gameId" element={<GameDetail/>}/>
                <Route path={start} element={<Start/>}/>
                <Route path="search" element={<Search/>}/>
                <Route path="discover/:questionNumber" element={<Discover/>}/>
                <Route path="scan" element={<Scan/>}/>
                <Route path="maintenance" element={<Maintenance/>}/>
            </Routes>
        </div>
    );
}

export default App;
