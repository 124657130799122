export const MIN_15 = 'MIN_15';
export const MIN_15_30 = 'MIN_15_30';
export const MIN_30_60 = 'MIN_30_60';
export const MIN_60_90 = 'MIN_60_90';
export const MIN_90_120 = 'MIN_90_120';
export const MIN_120 = 'MIN_120';

export const ALL_GAME_DURATIONS = [
    MIN_15,
    MIN_15_30,
    MIN_30_60,
    MIN_60_90,
    MIN_90_120,
    MIN_120,
];

export const GAME_DURATION_STRINGS:object = {
    MIN_15: '< 15 min',
    MIN_15_30: '15 - 30 min',
    MIN_30_60: '30 - 60 min',
    MIN_60_90: '60 - 90 min',
    MIN_90_120: '90 - 120 min',
    MIN_120: '> 120 min',
}
