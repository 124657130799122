import axios from "axios";
import {getBaseUrl} from "./index";

export async function isInMaintenanceMode(): Promise<boolean> {
    return axios.get(getBaseUrl() + 'maintenance')
        .then(response => {
            if (response.data.success) {
                return false;
            } else {
                return true;
            }
        })
}
