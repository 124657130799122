// @flow
import * as React from 'react';
import {SpotLightItem} from "./SpotLightItem";
import {useDispatch, useSelector} from "react-redux";
import {Game} from "../../../models/Game";
import {RootState} from "../../../redux/reducers/rootReducer";
import {useNavigate} from "react-router-dom";
import {setCurrentGame} from "../../../redux/actions/GameActions";

type Props = {};

export function SpotLight(props: Props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const games = useSelector((state: RootState) => state.gameReducer.popularGames);

    const showGameDetail = (game: Game) => {
        dispatch(setCurrentGame(game));
        navigate(`games/${game.id}`);
    }

    return (
        <div className="spotlight">
            {
                games?.map((game: Game) => {
                    return <SpotLightItem
                        key={game.id}
                        image={game.image}
                        onClick={() => showGameDetail(game)}
                    />
                })
            }
        </div>
    );
}
