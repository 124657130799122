// @flow
import React, {ReactNode} from 'react';

type Props = {
    content: string,
    children: ReactNode,
};

export function QuestionHolder(props: Props) {
    return (
        <div className="question">
            <h1>{props.content}</h1>

            <ul>
                {props.children}
            </ul>
        </div>
    );
}
