// @flow
import React, {ReactNode} from 'react';

type Props = {
    children: ReactNode,
};

export function PageContent(props: Props) {
    return (
        <div className="page__content">
            {props.children}
        </div>
    );
}
