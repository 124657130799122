// @flow
import React, {ReactNode} from 'react';

type Props = {
    count: number,
};

Spacer.defaultProps = {
    count: 1,
}

export function Spacer(props: Props) {
    const getElements = (): Array<ReactNode> => {
        let elements = [];

        for (let i = 0; i < props.count; i++) {
            elements.push(
                <div key={`spacerId${i}`} className="spacer"/>
            );
        }

        return elements;
    }

    return (
        <>
            {getElements()}
        </>
    );
}
