import produce from "immer";
import {SET_CURRENT_GAME, SET_FILTERED_GAMES, SET_GAMES_TO_FILTER, SET_POPULAR_GAMES} from "../types/GameActionTypes";
import {Game} from "../../models/Game";

interface InitialState {
    popularGames: Game[],
    gamesToFilter: Game[],
    filteredGames: Game[],
    currentGame: Game | null,
}

const initialState: InitialState = {
    popularGames: [],
    gamesToFilter: [],
    filteredGames: [],
    currentGame: null,
}

type Action = {
    type: string,
    payload: any,
}

export const gameReducer = (state = initialState, action: Action) => {
    return produce(state, nextState => {
        switch (action.type) {
            case SET_POPULAR_GAMES:
                nextState.popularGames = action.payload;
                break;
            case SET_GAMES_TO_FILTER:
                nextState.gamesToFilter = action.payload;
                break;
            case SET_FILTERED_GAMES:
                nextState.filteredGames = action.payload;
                break;
            case SET_CURRENT_GAME:
                nextState.currentGame = action.payload;
                break;
        }
    })
}
