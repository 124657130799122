import produce from "immer";
import {SET_SCAN_MESSAGE} from "../types/AppActionTypes";

interface InitialState {
    scanMessage: string,
}

const initialState: InitialState = {
    scanMessage: '',
}

type Action = {
    type: string,
    payload: any,
}

export const appReducer = (state = initialState, action: Action) => {
    return produce(state, nextState => {
        switch (action.type) {
            case SET_SCAN_MESSAGE:
                if (action.payload) {
                    nextState.scanMessage = action.payload;
                } else {
                    nextState.scanMessage = 'Scan een barcode met de scanner onder het scherm';
                }
                break;
        }
    })
}
