import React from 'react';
import {AppHolder} from "../../shared/layout/AppHolder";
import {AppContent} from "../../shared/layout/AppContent";
import {Results} from "../../shared/other/results/Results";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers/rootReducer";

type Props = {};

export function DiscoverResults(props: Props) {
    const {filteredGames} = useSelector((state: RootState) => state.gameReducer);

    return (
        <AppHolder>
            <AppContent>
                <Results games={filteredGames}/>
            </AppContent>
        </AppHolder>
    );
}
