// @flow
import React, {useEffect, useState} from 'react';
import {QuestionHolder} from "./QuestionHolder";
import {AnswerButton} from "./AnswerButton";
import {PLAYER_COUNT} from "../../../constants/Questions";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers/rootReducer";
import {numberIsInRange} from "../../../helpers/StringHelper";

type Props = {
    onClick: Function,
};

export function PlayerCountQuestion(props: Props) {
    const {filteredGames} = useSelector((state: RootState) => state.gameReducer);

    const [answers, setAnswers] = useState<Array<number>>([])

    useEffect(() => {
        setAnswers(getAnswersToShow);
    }, [filteredGames]);

    const getAnswersToShow = () => {
        let answersToShow: number[] = [];

        Object.values(PLAYER_COUNT).forEach(answer => {
            filteredGames.forEach(game => {
                if (!answersToShow.includes(answer)) {
                    if (numberIsInRange(answer, game.properties.amountOfPlayers)) {
                        answersToShow.push(answer);
                    }
                }
            });
        })

        return answersToShow;
    }

    return (
        <QuestionHolder content="Hoeveel personen moeten minimum kunnen meespelen?">
            {
                answers.map(answer => {
                    return <AnswerButton
                        key={`playerCountAnswer${answer}`}
                        content={"" + answer}
                        onClick={() => props.onClick(answer)}
                    />
                })
            }
        </QuestionHolder>
    );
}
