import React, {useEffect, useRef, useState} from 'react';
import Keyboard, {KeyboardReactInterface} from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers/rootReducer";

type Props = {
    show: boolean,
    onChange: Function,
};

export function OnScreenKeyboard(props: Props) {
    const DEFAULT_LAYOUT = 'default';
    const SHIFT_LAYOUT = 'shift';

    const [layoutName, setLayoutName] = useState<string>();

    const {searchKeyword} = useSelector((state: RootState) => state.filterReducer);

    const keyboardRef = useRef<KeyboardReactInterface | null>(null);

    useEffect(() => {
        keyboardRef.current?.setInput(searchKeyword);
    }, [searchKeyword]);

    const onKeyPress = (button: string) => {
        if (button === "{shift}" || button === "{lock}") {
            setLayoutName(layoutName === DEFAULT_LAYOUT ? SHIFT_LAYOUT : DEFAULT_LAYOUT);
        }
    };

    return (
        <div className={!props.show ? 'keyboard__hidden' : ''}>
            <Keyboard
                keyboardRef={(r) => (keyboardRef.current = r)}
                onChange={(input) => props.onChange(input)}
                onKeyPress={onKeyPress}
                layoutName={layoutName}
                layout={{
                    default: [
                        "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                        "{tab} a z e r t y u i o p [ ] \\",
                        "{lock} q s d f g h j k l m ' {enter}",
                        "{shift} w x c v b n ; , . / {shift}",
                        ".com @ {space}"
                    ],
                    shift: [
                        "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                        "{tab} A Z E R T Y U I O P { } |",
                        '{lock} Q S D F G H J K L M " {enter}',
                        "{shift} W X C V B N : < > ? {shift}",
                        ".com @ {space}"
                    ]
                }}
                theme={"hg-theme-default hg-layout-numeric numeric-theme"}
            />
        </div>
    );
}
