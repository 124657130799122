import {combineReducers} from "redux";
import {gameReducer} from "./gameReducer";
import {filterReducer} from "./filterReducer";
import {appReducer} from "./appReducer";

export const rootReducer = (combineReducers)({
    gameReducer: gameReducer,
    filterReducer: filterReducer,
    appReducer: appReducer
});

export type RootState = ReturnType<typeof rootReducer>;
