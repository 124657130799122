import * as React from 'react';
import {Helmet} from "react-helmet";
import {AppContent} from "../../shared/layout/AppContent";
import {PageContent} from "../../shared/layout/PageContent";
import {AppHolder} from "../../shared/layout/AppHolder";
import {Header} from "../../shared/partials/Header";
import {useEffect} from "react";
import {isInMaintenanceMode} from "../../../api/MaintenanceService";
import {useNavigate} from "react-router-dom";

type Props = {};

export function Maintenance(props: Props) {
    const navigate = useNavigate();

    useEffect(() => {
        const id = setInterval(checkServerStatus, 5000);

        return () => clearInterval(id);
    }, []);

    const checkServerStatus = () => {
        isInMaintenanceMode().then(inMaintenanceMode => {
            if (!inMaintenanceMode) navigate('/');
        });
    }

    return (
        <>
            <Helmet>
                <title>Maintenance</title>
            </Helmet>

            <Header/>

            <AppHolder>
                <AppContent>
                    <PageContent>
                        <h1>De kiosk is even onbeschikbaar</h1>
                        <p>We voeren even een update uit, de kiosk is zo dadelijk weer beschikbaar.</p>
                    </PageContent>
                </AppContent>
            </AppHolder>
        </>
    );
}
