// @flow
import React, {useEffect, useState} from 'react';
import {QuestionHolder} from "./QuestionHolder";
import {AnswerButton} from "./AnswerButton";
import {DURATION} from "../../../constants/Questions";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers/rootReducer";
import {durationFoundInDurationString} from "../../../helpers/FilterHelper";
import {GAME_DURATION_STRINGS} from "../../../constants/GameDurations";

type Props = {
    onClick: Function,
};

export function DurationQuestion(props: Props) {
    const {filteredGames} = useSelector((state: RootState) => state.gameReducer);

    const [answers, setAnswers] = useState<Array<string>>([]);

    useEffect(() => {
        setAnswers(getAnswersToShow());
    }, [filteredGames]);

    const getAnswersToShow = (): Array<string> => {
        let answersToShow: string[] = [];

        Object.values(DURATION).forEach(answer => {
            filteredGames.forEach(game => {
                if (!answersToShow.includes(answer)) {
                    if (durationFoundInDurationString(game.properties.duration, answer)) {
                        answersToShow.push(answer);
                    }
                }
            })
        });

        return answersToShow;
    }

    const getDurationStringByAnswer = (answer: string): string => {
        // @ts-ignore
        return GAME_DURATION_STRINGS[answer];
    }

    return (
        <QuestionHolder content="Hoe lang mag het spel duren?">

            {
                answers.map(answer => {
                    return <AnswerButton
                        key={`durationAnswer${answer}`}
                        content={getDurationStringByAnswer(answer)}
                        onClick={() => props.onClick(answer)}
                    />;
                })
            }

        </QuestionHolder>
    );
}
