// @flow
import * as React from 'react';
import {Game} from "../../../../models/Game";

type Props = {
    game: Game,
    onClick: Function,
};

export function ResultItem(props: Props) {
    return (
        <span className="results__item" onClick={() => props.onClick()}>
            <div className="results__item__image">
                <img src={props.game.image} alt="game cover"/>
            </div>
            <div className="results__item__content">
                <strong>{props.game.name}</strong>
            </div>
        </span>
    );
}
