import * as React from 'react';
import {AppHolder} from "../../shared/layout/AppHolder";
import {AppContent} from "../../shared/layout/AppContent";
import {Header} from "../../shared/partials/Header";
import {useNavigate} from "react-router-dom";
import barcodeImage from '../../../assets/images/kiosk-barcode.png';
import {ArrowDownward} from "@mui/icons-material";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers/rootReducer";

type Props = {};

export function Scan(props: Props) {
    const navigate = useNavigate();

    const {scanMessage} = useSelector((state: RootState) => state.appReducer);

    return (
        <>
            <Helmet>
                <title>Scannen</title>
            </Helmet>

            <Header
                showHomeButton
                showBackButton
                onBackClick={() => navigate('/start')}
            />

            <AppHolder>
                <AppContent>
                    <div className="page__content page__content--scan">
                        <div className="scan__content">
                            <p>{scanMessage}</p>
                            <img className="scan__image" src={barcodeImage} alt={"Afbeelding van kiosk"}/>
                            <span className="scan__icon"><ArrowDownward/></span>
                        </div>
                    </div>
                </AppContent>
            </AppHolder>
        </>
    );
}
