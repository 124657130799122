import {
    RESET_FILTERS,
    SET_AGE,
    SET_DURATION,
    SET_EXPERIENCE,
    SET_PLAYER_COUNT,
    SET_SEARCH_KEYWORD
} from "../types/FilterActionTypes";

export function setAge(age: number) {
    return {
        type: SET_AGE,
        payload: age,
    }
}

export function setPlayerCount(playerCount: number) {
    return {
        type: SET_PLAYER_COUNT,
        payload: playerCount,
    }
}

export function setDuration(duration: string) {
    return {
        type: SET_DURATION,
        payload: duration,
    }
}

export function setExperience(experience: number) {
    return {
        type: SET_EXPERIENCE,
        payload: experience,
    }
}

export function setSearchKeyword(keyword: string) {
    return {
        type: SET_SEARCH_KEYWORD,
        payload: keyword,
    }
}

export function resetFilters() {
    return {
        type: RESET_FILTERS,
    }
}
