// @flow
import * as React from 'react';
import {Game} from "../../../../models/Game";

type Props = {
    game: Game,
};

export function GamePropertiesTable(props: Props) {
    return (
        <table cellPadding="0" cellSpacing="0">
            <tbody>
            <tr>
                <th>Voorraad</th>
                <td>
                    {
                        props.game.stock ?
                            <span className="label label--success">Op voorraad</span> :
                            <span className="label label--danger">Niet op voorraad</span>
                    }
                </td>
            </tr>
            <tr>
                <th>Leeftijd</th>
                <td>{props.game.properties.age ?? 'Onbekend'}</td>
            </tr>
            <tr>
                <th>Aantal spelers</th>
                <td>{props.game.properties.amountOfPlayers ?? 'Onbekend'}</td>
            </tr>
            <tr>
                <th>Speelduur</th>
                <td>{props.game.properties.duration ?? 'Onbekend'}</td>
            </tr>
            </tbody>
        </table>
    );
}
