import {GameData} from "./types/GameData";
import {removeHtmlFromString} from "../helpers/StringHelper";
import {GameProperties} from "./GameProperties";
import {decode} from "html-entities";

export class Game {
    id: number;
    name: string;
    price: number;
    image: string;
    description: string;
    stock: number;
    properties: GameProperties;
    types: Array<string>;

    constructor(data: GameData) {
        this.id = data.id;
        this.name = data.name;
        this.description = decode(removeHtmlFromString(data.description));
        this.price = data.price;
        this.image = data.image;
        this.stock = data.stock;
        this.properties = data.properties;
        this.types = data.types;
    }
}
