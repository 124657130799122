// @flow
import * as React from 'react';
import {Game} from "../../../../models/Game";
import {Spacer} from "../../layout/Spacer";
import {ResultItem} from "./ResultItem";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setCurrentGame} from "../../../../redux/actions/GameActions";

type Props = {
    games: Array<Game>,
};

export function Results(props: Props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const navigateToDetail = (game: Game) => {
        dispatch(setCurrentGame(game));
        navigate(`/games/${game.id}`);
    }

    return (
        <>
            <div className="page__content">
                {
                    props.games.length ? <>
                        <Spacer/>
                        <h1>Zoekresultaten</h1>
                        <Spacer/>
                    </> : null
                }
            </div>

            <div className="results">
                {
                    props.games.map(game => {
                        return <ResultItem
                            key={game.id}
                            game={game}
                            onClick={() => navigateToDetail(game)}
                        />
                    })
                }
            </div>
        </>
    );
}
